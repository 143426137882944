
import axios from "@/plugins/axios.js"

const actions = {
    fetchTransactions({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        axios.get(`/transactions?${new URLSearchParams(payload).toString()}`)
          .then((response) => {
            if(response.data.success){
                commit('SET_TRANSACTIONS', response.data.data)
              }
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
}

const getters = {}

const mutations = {
    SET_TRANSACTIONS(state, data) {
      state.pageData.data = data
    },
    ADD_TRANSACTION(state, item) {
        state.pageData.data.unshift(item)
    },
}

const state = {
  pageData: {
    data: []
  },
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

