
import axios from "@/plugins/axios.js"

const actions = {
  fetchChats({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/chats`)
        .then((response) => {
          if(response.data.success){
            commit('SET_CHATS', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

const getters = {
  getChats: (state, getters, rootState) => {
      return state.chats.filter((ct) => ct.content.toLowerCase().includes(state.chatSearchQuery.toLowerCase()))
  },
}

const mutations = {
  SET_CHATS(state, chats) {
    state.chats = [...chats]
  },
  ADD_CHAT(state, chat) {
    state.chats.push(chat)
  },
  UPDATE_CHAT(state, chat){
    const dataIndex = state.chats.findIndex((f) => f.id == chat.id)
    Object.assign(state.chats[dataIndex], chat)
  },
  DELETE_CHAT(state, chatId){
    const ItemIndex = state.chats.findIndex((item) => item.id == chatId)
    state.chats.splice(ItemIndex, 1);
  },
  SET_CHAT_SEARCH_QUERY(state, query) {
    state.chatSearchQuery = query
  },
  MARK_SEEN_ALL_CHATS(state, payload) {
    payload.chatData.forEach((msg) => {
      msg.is_read = 1
    })
  },
}

const state = {
  chats:[],
  chatSearchQuery: ""
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

